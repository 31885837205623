import React from "react";
import "./button.css";

const PrefixButton = ({ className, prefixIcon, children, onClick = () => {}, isMobile, isConnectORSaveORMessageORSchedule }) => {
  const handleClick = (e) => {
    if (!isMobile && isConnectORSaveORMessageORSchedule === "isConnectORSaveORMessageORSchedule") {
      // If it's not a mobile device, show the alert
      e.preventDefault();  // Prevent the default action (e.g., navigating to the tel: link)
      alert("Please use the app on a mobile device to use this feature.");
    } else {
      // If it's a mobile device, proceed with the normal click behavior
      onClick();  
    }
  };

  return (
    <button
      className={`${className} flex items-center justify-center relative text-sm md:text-base`}
      onClick={handleClick}
    >
      {prefixIcon && <span className="absolute prefix-icon">{prefixIcon}</span>}
      {children}
    </button>
  );
};

export default PrefixButton;
