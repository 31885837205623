import React from 'react';
import { FaTwitter, FaFacebook, FaWhatsapp, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import "../components/SocialShareModal.css"

const SocialShareModal = ({ show, onClose, link }) => {
  if (!show) return null;

  // Handle the social media share when an icon is clicked
  const handleShare = (platform) => {
    let shareUrl = '';
    const encodedLink = encodeURIComponent(link);

    switch (platform) {
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?url=${encodedLink}&text=Check%20this%20out!`;
        break;
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`;
        break;
      case 'whatsapp':
        shareUrl = `https://wa.me/?text=${encodedLink}`;
        break;
      case 'linkedin':
        shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedLink}&title=Check%20this%20out!&summary=Interesting%20link&source=MyWebsite`;
        break;
      case 'email':
        shareUrl = `mailto:?subject=Check%20this%20out&body=${encodedLink}`;
        break;
      default:
        return;
    }

    // Open the share URL in a new window
    window.open(shareUrl, '_blank', 'noopener,noreferrer');
    onClose(); // Close the modal after sharing
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h3 className="modal-title">Share This Link</h3>
          <button className="close-btn" onClick={onClose}>
            <span className="close-icon">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="social-icons">
            <FaTwitter onClick={() => handleShare('twitter')} />
            <FaFacebook onClick={() => handleShare('facebook')} />
            <FaWhatsapp onClick={() => handleShare('whatsapp')} />
            <FaLinkedin onClick={() => handleShare('linkedin')} />
            <FaEnvelope onClick={() => handleShare('email')} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialShareModal;
